<template lang="pug">
b-modal#modal-wancoin(
  v-model="show"
  title="異動旺幣", 
  title-class="font-18", 
  body-class="p-4" 
  size="md"
)

  form.form-horizontal(role="form")
    .row
      .col-12
        .form-group.mb-3
          label 加給/扣除
          br
          multiselect(
            :value="selectedType", 
            @input="onTypeChange"
            :options="typeOptions", 
            placeholder="請選擇異動類型",
            deselect-label="Can't remove this value"
            :multiple="false",
            :allow-empty="false",
            track-by="id",
            label="name"
            select-label=""
          )
      .col-12
        .form-group.mb-3
          label 異動數量
          br
          input.form-control(v-model="formData.quy", type="number", placeholder="請輸入異動數量")
      .col-12
        .form-group.mb-3
          label 異動原因
          br
          input.form-control(v-model="formData.reason", type="text",placeholder="請輸入異動原因")


  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button(size='md' variant='success' @click='clickOk')
      | 確認

</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  props: ['showModal'],
  data() {
    return {
      show: false,
      formData: {
        type: 1,
        quy: null,
        reason: ''
      },
      typeOptions: [
        {
          id: 1,
          name: '加給'
        },
        {
          id: 2,
          name: '扣除'
        }
      ],
      selectedType:  {
        id: 1,
        name: '加給'
      }
    }
  },
  components: {
    Multiselect
  },
  watch: {
    showModal(_status) {
      this.show = _status
    }
  },
  methods: {
    isInteger(input) {
      const parsedInput = parseInt(input, 10);
      if (/^[1-9]\d*$/.test(input) && Number.isInteger(parsedInput)) {
        return parsedInput.toString() === input;
      } else {
        return false;
      }
    },
    checkInput() {
      // 檢查整數
      if (!this.isInteger(this.formData.quy)) {
        this.$root.common.showErrorDialog('異動數量必須是正整數！')
        return false
      }
      // 檢查原因
      if (this.formData.reason === '') {
        this.$root.common.showErrorDialog('異動原因為必填！')
        return false
      }
      return true
    },
    clickOk() {
      if(!this.checkInput()) return
      this.$emit('submit', this.formData)
    },
    onTypeChange(_options) {
      this.selectedType = _options
      this.formData.type = _options.id
    }
  }
}
</script>
<style lang="sass" scoped>

</style>
