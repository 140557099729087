<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  WancoinModal(:show-modal="showModal" @submit="onModalSubmit")
  .row
    .col-lg-12.d-flex.justify-content-end
      b-button.width-md.mb-3(variant="secondary" @click="$router.go(-1)") 回上一層 
    .col-lg-12
      //- 基本資料
      b-card.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-md-4
                .form-group.mb-3
                  label 用戶編號
                  br
                  input.form-control(v-model="memberData.code", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 姓名
                  br
                  input.form-control(v-model="memberData.user_name", type="text", placeholder="請輸入用戶姓名")
              .col-md-4
                .form-group.mb-3
                  label 手機號碼
                  br
                  input.form-control(v-model="memberData.user_phone", type="text",placeholder="請輸入手機號碼")
              .col-md-4
                .form-group.mb-3
                  label Email
                  br
                  .row
                    .col-6.d-flex.align-items-center
                      input.form-control(v-model="emailPrefix", type="text")
                      span.email-control @
                    .col-6
                      input.form-control(v-model="emailSuffix", type="text")
              .col-md-4
                .form-group.mb-3
                  label 好朋友等級
                  br
                  input.form-control(v-model="friendsLevel" type="text", disabled)
              .col-md-4
                .form-group.mb-3
                  label 帳戶狀態
                  br
                  multiselect(
                    :value="accountStatus", 
                    @input="onAccountStatusChange"
                    :options="statusOptions", 
                    placeholder="請選擇啟用狀態",
                    deselect-label="Can't remove this value"
                    :multiple="false",
                    :allow-empty="false",
                    track-by="id",
                    label="name"
                    select-label=""
                  )
              .col-md-4
                .form-group.mb-3
                  label 最近一次登入時間
                  br
                  input.form-control(v-model="memberData.last_login", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 上次變更資料時間
                  br
                  input.form-control(v-model="memberData.last_update", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 註冊來源
                  br
                  input.form-control(v-model="memberData.source", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 註冊時間
                  br
                  input.form-control(v-model="memberData.register_time", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 綁定google帳號時間
                  br
                  input.form-control(v-model="memberData.bind_google_at", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 綁定facebook帳號時間
                  br
                  input.form-control(v-model="memberData.bind_facebook_at", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 綁定Line帳號時間
                  br
                  input.form-control(v-model="memberData.bind_line_at", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 綁定Apple帳號時間
                  br
                  input.form-control(v-model="memberData.bind_apple_at", type="text" disabled)
              .col-md-4
                .form-group.mb-3
                  label 旺幣數
                  br
                  .d-flex.align-items-center
                    input.form-control(v-model="memberData.wancoin", type="text" disabled)
                    b-button.width-sm.ml-2(variant="primary" v-b-modal.modal-wancoin @click="onModalClick") 管理
              .col-md-4
                .form-group.mb-3
                  label 金徽章數
                  br
                  .d-flex.align-items-center
                    input.form-control(v-model="memberData.badge", type="text" disabled)
                    b-button.width-sm.ml-2(variant="primary" @click="$router.push({ path: '/upgradetask'})") 查看歷程
              .col-md-4
                .form-group.mb-3
                  label 其他特殊備註
                  br
                  input.form-control(v-model="note", type="text",placeholder="其他特殊備註")

            .row
              .col-12.d-flex.justify-content-end
                b-button.width-lg(variant="primary" @click="updateMemberDetail" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}


      //- 旺幣異動歷程
      .card
        .card-body
          .header-row.mb-3
            .header-title 旺幣異動歷程
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="wancoinHistoryTable"
                :items="wancoinHistoryData", 
                :fields="wancoinHistoryFields", 
                responsive,
                :per-page="wancoinHistory.perPage",
                :current-page="wancoinHistory.currentPage")

                template(v-slot:cell(wancoin)="data")
                  span(v-if="data.item.wancoin && data.item.type === 0") - {{ data.item.wancoin }}
                  span(v-else-if="data.item.wancoin && data.item.type === 1") + {{ data.item.wancoin }}
                  span(v-else) -

                template(v-slot:cell(editer)="data")
                  span {{ data.item.editerDetail?.name || '-'}}

          .row
            .col-12.d-flex.justify-content-end
              ul.pagination.pagination-rounded.mb-0
                b-pagination(v-model="wancoinHistory.currentPage", :total-rows="wancoinHistory.totalRows", :per-page="wancoinHistory.perPage")


      //- 帳號綁定信用卡紀錄
      .card
        .card-body
          .header-row.mb-3
            .header-title 帳號綁定信用卡紀錄
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="bindCardHistoryTable"
                :items="bindCardHistoryData", 
                :fields="bindCardHistoryFields", 
                hover
                responsive="sm",
                :per-page="bindCardHistory.perPage",
                :current-page="bindCardHistory.currentPage")

                template(v-slot:cell(action)="data")
                  span(v-if="data.item.deleted_at !== ''") 解除綁定
                  span(v-else) 綁定

                template(v-slot:cell(card)="data")
                  span {{ data.item.type }} {{ data.item.card }}
                
                template(v-slot:cell(time)="data")
                  span {{ data.item.deleted_at !== '' ? data.item.deleted_at : data.item.created_at }}

          .row
            .col-12.d-flex.justify-content-end
              ul.pagination.pagination-rounded.mb-0
                b-pagination(v-model="bindCardHistory.currentPage", :total-rows="bindCardHistory.totalRows", :per-page="wancoinHistory.perPage") 
            
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import WancoinModal from '@/components/WancoinModal'


/**
 * Starter component
 */
export default {
  head () {
    return {};
  },
  data () {
    return {
      title: "用戶查詢",
      items: [
        {
          text: "用戶/等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "用戶查詢",
          active: true,
        }
      ],
      currentMemeberId: '',
      memberData:  {},
      emailPrefix: '',
      emailSuffix: '',
      accountStatus: {},
      note: '',
      statusOptions: [
        {
          id: 1, 
          name: "正常" 
        },
        {
          id: 2, 
          name: "停權" 
        },
        {
          id: 3, 
          name: "封鎖"
        }
      ],
      // 旺幣異動歷程
      wancoinHistoryData: [],
      wancoinHistoryFields: [
        {
          key: "total",
          label: "異動後旺幣數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "wancoin",
          label: "數量異動",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "title",
          label: "原因",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "get_at",
          label: "時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "editer",
          label: "建立者",
          sortable: false,
          class: 'width-auto center'
        },
      ],
      wancoinHistory: {
        currentPage: 1,
        perPage: 25,
        totalRows: 1
      },

      // 信用卡紀錄
      bindCardHistoryData: [],
      bindCardHistoryFields: [
        {
          key: "action",
          label: "行為",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "card",
          label: "信用卡資訊",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "time",
          label: "時間",
          sortable: false,
          class: 'width-auto center'
        },
      ],
      bindCardHistory: {
        currentPage: 1,
        perPage: 25,
        totalRows: 1
      },
      showModal: false,
      saving: false
    };
  },
  computed: {
    friendsLevel() {
      switch (this.memberData.type) {
        case 1:
          return '中天好朋友'
        case 2:
          return '麻吉好朋友'
        case 3:
          return '超級好朋友'
        default:
          return '-'
      }
    }
  },
  mounted () {
    this.currentMemeberId = this.$route.params.memberHashID
    this.getMemberDetail()
    this.getMemberWanCoinList()
    this.getMemberBindCardList()
  },
  methods: {
    getMemberDetail() {
      console.log('id', this.currentMemeberId)
      const vm = this
      this.$root.apis.getMemberDetail(this.currentMemeberId, 
        function (_response) {
          const response = _response.body.data
          vm.memberData = response
          vm.emailPrefix = response.user_email.split('@')[0]
          vm.emailSuffix = response.user_email.split('@')[1]
          vm.accountStatus = vm.statusOptions.find(_status => _status.id === response.status)
        },
        function (_error) {
          vm.$root.common.showErrorDialog(_error.body.data)
        })
    },
    getMemberWanCoinList() {
      const vm = this
      this.$root.apis.getMemberWanCoinList(this.currentMemeberId, 
        function (_response) {
          const response = _response.body.data
          vm.wancoinHistoryData = response.items
          vm.wancoinHistory.currentPage = response.page
          vm.wancoinHistory.perPage = response.per_page
          vm.wancoinHistory.totalRows = response.total
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        })
    },
    getMemberBindCardList() {
      const vm = this
      this.$root.apis.getMemberBindCardList(this.currentMemeberId, 
        function (_response) {
          const response = _response.body.data
          vm.bindCardHistoryData = response.items
          vm.bindCardHistory.currentPage = response.page
          vm.bindCardHistory.perPage = response.per_page
          vm.bindCardHistory.totalRows = response.total
        },
        function (_error) {
          vm.$root.common.showErrorDialog(_error.body.data)
        })
    },
    onAccountStatusChange(_option) {
      this.accountStatus = _option
      this.memberData.status = _option.id
    },
    onModalClick() {
      this.showModal = true
    },
    onModalSubmit(_payload) {
      this.updateMemberWanCoin(_payload)
      this.showModal = false
    },
    updateMemberWanCoin(_payload) {
      const vm = this
      this.$root.apis.updateMemberWanCoin(this.currentMemeberId, _payload,
        function (_response) {
          vm.getMemberDetail()
          vm.getMemberWanCoinList()
        },
        function (_error) {
          vm.$root.common.showErrorDialog(_error.body.data)
        })
    },
    updateMemberDetail() {
      this.saving = true
      const vm = this
      const data = {
        user_name: this.memberData.user_name,
        user_phone: this.memberData.user_phone,
        email_account: this.emailPrefix,
        email_domain: this.emailSuffix,
        status: this.memberData.status,
        note: this.note
      }
      this.$root.apis.updateMemberDetail(this.currentMemeberId, data,
        function (_response) {
          vm.$root.common.showSingleNotify('更新成功！')
          vm.getMemberDetail()
          vm.saving = false
        },
        function (_error) {
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        })
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    WancoinModal
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.email-control
  margin-left: 12px
  margin-right: -12px
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  background: #f1f5f7
::v-deep td
  &.b-table-sticky-column:last-child
    background: #fff !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
